<template>
  <chip-base
    v-bind="$attrs"
    v-on="$listeners"
    base-url="/category-filter-item"
    image
  />
</template>

<script>
import ChipBase from './ChipBase';
export default {
  components: { ChipBase }
}
</script>
