<template>
  <table-view
    ref="table"
    api="categoryFilter"
    v-model="dialog"
    :filters="filters"
    :headers="headers"
    :popup="popup"
    :save="save"
    :edit="edit"
    :compute="compute"
    :dialog-props="dialogProps"
    @click:item="edit( $event.item.item )"
    selectable
  >
  <template v-slot:item.value.name="{ value, item }">

    <span :class="{ 'font-weight-medium': isMobile }">
      {{ value }}
    </span>
    <template v-if="isMobile">
      <br/>
      <span class="caption">
        {{ item.categoryName }}
      </span>
    </template>

  </template>
  <template v-slot:item.value.idCategory="{ item }">

    <category
      :item="item"
      item-value="idCategory"
      item-text="categoryName"
      small link
    />

  </template>
  <template v-slot:item.value.filterItems="{ value }">
    <template v-if="value.length">

      <category-filter-item
        class="ma-1"
        v-for="( filterItem, index ) in value"
        :key="index"
        :item="filterItem"
        color="grey lighten-3"
        small link
      />

    </template>
    <span v-else/>
  </template>
  <template v-slot:form>

    <category-filter-form
      ref="form"
      v-model="model"
      :is-new="isNew"
      :save="save"
    />

  </template>
  <template v-slot:footer>

    <v-spacer/>

    <btn
      class="mr-2"
      color="primary"
      :loading="exporting"
      @click.stop="exportData"
    >
      {{ $t('btn.export') }}
    </btn>

    <btn @click.stop="edit()">
      {{ $t('categoryFilter.btn.new') }}
    </btn>

  </template>

  </table-view>
</template>

<script>
import TableView from '@/components/TableView';
import Category from '@/components/values/Category';
import CategoryFilterItem from '@/components/values/CategoryFilterItem';
import CategoryFilterForm from './Form';
import { ICONS } from '@/utils/constants';
import { mapState } from 'vuex';

export default {
  name: 'CategoryFilters',
  auth: true,
  head: vm => ({ title: vm.$i18n.t('categoryFilter.title') }),
  layout: 'admin',
  layoutProps: ({ i18n }) => ({
    title: i18n.t('categoryFilter.title'),
    icon: ICONS.categoryFilter
  }),
  components: {
    TableView,
    CategoryFilterItem,
    CategoryFilterForm,
    Category
  },
  data: () => ({
    model: {},
    dialog: false,
    exporting: false,
    isNew: false,
    filters: [],
    popup: {}
  }),
  computed: {
    ...mapState( 'app', [ 'isMobile' ]),
    headers() {
      return [
        {
          key: 'name',
          text: this.$t('inputs.name.label'),
          mobile: true
        },
        {
          key: 'idCategory',
          text: this.$t('inputs.idCategory.label'),
          filter: {
            is: 'api-field',
            key: 'category',
            api: 'category',
            itemSearch: 'name',
            clearable: true,
            multiple: true
          }
        },
        {
          key: 'filterItems',
          text: this.$t('inputs.filterItems.label'),
          filter: {
            is: 'api-field',
            api: 'categoryFilterItem',
            itemSearch: 'name',
            clearable: true,
            multiple: true
          }
        }
      ];
    },
    dialogProps() {
      return {
        title: this.$t('categoryFilter.alerts.removeTitle'),
        text: this.$t('categoryFilter.alerts.removeText')
      }
    }
  },
  methods: {
    compute( items ) {
      return items.map( item => {
        item.filterItems = ( item.filterItems || [] ).filter( i => i.status !== -1 );
        return item;
      });
    },
    edit( item ) {

      this.dialog = true;
      this.isNew = !item;
      this.popup = {
        ...this.popup,
        title: this.$t(`categoryFilter.btn.${ item ? 'edit': 'new' }`)
      };

      if ( ! this.isNew ) {
        this.$router.replace({
          path: this.$route.path,
          params: this.$route.params,
          query: { ...this.$route.query, id: item.id }
        }).catch(() => {});
      }

      this.$nextTick(() => {
        this.$refs.form.resetValidation();
        this.model = {
          id: -1,
          status: 1,
          ...item
        };
      });
    },
    exportData() {
      if ( this.exporting ) return;
      this.exporting = true;
      this.$refs.table.exportData().finally(() => {
        this.exporting = false;
      });
    },
    sanitize( model ) {
      return {
        ...model,
        category: model.idCategory,
        idCategory: null
      };
    },
    save() {
      if ( this.$refs.form.validate()) {
        this.dialog = false;
        this.model = this.$refs.form.model;
        this.$store
          .dispatch('api/categoryFilter/set', this.sanitize( this.model ))
          .then(() => this.$refs.table.refresh())
          .then(() => {
            this.$store.dispatch( 'app/success', {
              message: this.$t('alerts.save')
            });
          }).catch( err => {
            this.$store.dispatch( 'app/error', {
              message: err.message
            });
          });
      }
    }
  }
}
</script>
