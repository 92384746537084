<template>
  <v-form
    ref="form"
    class="pa-2"
    @submit.prevent="onSave"
  >

    <text-field
      v-model="model.name"
      :rules="rules.name"
      :label="$t('inputs.name.label')"
    />

    <api-field
      v-model="model.idCategory"
      :label="$t('inputs.idCategory.label')"
      :rules="rules.idCategory"
      api="category"
      item-search="name"
      clearable
      chips
    />

    <template v-if="!isNew">
      <h3 class="title black--text mb-3">
        {{ $t('inputs.filterItems.label') }}
      </h3>

      <div v-if="filterItems.length" class="ma-n1">

        <category-filter-item
          class="ma-1"
          v-for="( filterItem, index ) in filterItems"
          :key="index"
          :item="filterItem"
          link
        />

      </div>

      <div v-else>
        {{ $t('categoryFilter.emptyFilterItems') }}
      </div>
    </template>

  </v-form>
</template>

<script>
import TextField from '@/components/form/TextField';
import ApiField from '@/components/form/ApiField';
import CategoryFilterItem from '@/components/values/CategoryFilterItem';
import { required } from '@/utils/rules';

export default {
  components: { TextField, ApiField, CategoryFilterItem },
  props: {
    value: Object,
    isNew: Boolean,
    save: Function
  },
  data() {
    return {
      model: this.value || {}
    }
  },
  computed: {
    rules() {
      return {
        name: [
          required( this.$t('inputs.name.rules.required'))
        ],
        idCategory: [
          required( this.$t('inputs.idCategory.rules.required'))
        ]
      };
    },
    filterItems() {
      return ( this.model.filterItems || [] ).filter( i => i.status !== -1 );
    }
  },
  watch: {
    value( model ) {
      this.model = model || {};
    }
  },
  methods: {
    onSave() {
      this.$emit( 'input', this.model );
      this.save && this.$nextTick( this.save );
    },
    validate() {
      return this.$refs.form && this.$refs.form.validate();
    },
    resetValidation() {
      return this.$refs.form && this.$refs.form.resetValidation();
    }
  }
}
</script>
