var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-view',{ref:"table",attrs:{"api":"categoryFilter","filters":_vm.filters,"headers":_vm.headers,"popup":_vm.popup,"save":_vm.save,"edit":_vm.edit,"compute":_vm.compute,"dialog-props":_vm.dialogProps,"selectable":""},on:{"click:item":function($event){return _vm.edit( $event.item.item )}},scopedSlots:_vm._u([{key:"item.value.name",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('span',{class:{ 'font-weight-medium': _vm.isMobile }},[_vm._v(" "+_vm._s(value)+" ")]),(_vm.isMobile)?[_c('br'),_c('span',{staticClass:"caption"},[_vm._v(" "+_vm._s(item.categoryName)+" ")])]:_vm._e()]}},{key:"item.value.idCategory",fn:function(ref){
var item = ref.item;
return [_c('category',{attrs:{"item":item,"item-value":"idCategory","item-text":"categoryName","small":"","link":""}})]}},{key:"item.value.filterItems",fn:function(ref){
var value = ref.value;
return [(value.length)?_vm._l((value),function(filterItem,index){return _c('category-filter-item',{key:index,staticClass:"ma-1",attrs:{"item":filterItem,"color":"grey lighten-3","small":"","link":""}})}):_c('span')]}},{key:"form",fn:function(){return [_c('category-filter-form',{ref:"form",attrs:{"is-new":_vm.isNew,"save":_vm.save},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})]},proxy:true},{key:"footer",fn:function(){return [_c('v-spacer'),_c('btn',{staticClass:"mr-2",attrs:{"color":"primary","loading":_vm.exporting},on:{"click":function($event){$event.stopPropagation();return _vm.exportData.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('btn.export'))+" ")]),_c('btn',{on:{"click":function($event){$event.stopPropagation();return _vm.edit()}}},[_vm._v(" "+_vm._s(_vm.$t('categoryFilter.btn.new'))+" ")])]},proxy:true}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})}
var staticRenderFns = []

export { render, staticRenderFns }